// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  FOLDERS: '/folder',
  EQUIPMENTS: '/equipment',
  TAGS: '/tags',
  REPORTS: '/report',
  USERS: '/users',
  SITES: '/sites',
  SETTINGS: '/settings',
  SUPPORT: '/support',
  ADMIN: '/admin',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    retrievePassword: `${ROOTS.AUTH}/retrievePassword`,
    emailValidation: `${ROOTS.AUTH}/emailValidation`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
  // FOLDERS
  folder: {
    root: ROOTS.FOLDERS,
    new: `${ROOTS.FOLDERS}/new`,
    view: (id: string | number) => `${ROOTS.FOLDERS}/${id}`,
    edit: (id: string | number) => `${ROOTS.FOLDERS}/${id}/edit`,
  },
  //TAGS
  tags: `${ROOTS.TAGS}`,
  // EQUIPMENTS
  equipment: {
    root: (folderId?: string | number) =>
      `${folderId ? `${ROOTS.FOLDERS}/${folderId}` : ''}${ROOTS.EQUIPMENTS}`,
    new: (folderId?: string | number) =>
      `${folderId ? `${ROOTS.FOLDERS}/${folderId}` : ''}${ROOTS.EQUIPMENTS}/new`,
    duplicate: (id: string | number, folderId?: string | number) =>
      `${folderId ? `${ROOTS.FOLDERS}/${folderId}` : ''}${ROOTS.EQUIPMENTS}/new?duplicateFrom=${id}`,
    details: (id: string | number, folderId?: string | number) =>
      `${folderId ? `${ROOTS.FOLDERS}/${folderId}` : ''}${ROOTS.EQUIPMENTS}/${id}`,
    edit: (id: string | number, folderId?: string | number) =>
      `${folderId ? `${ROOTS.FOLDERS}/${folderId}` : ''}${ROOTS.EQUIPMENTS}/${id}/edit`,
  },
  //REPORT
  reports: {
    root: ROOTS.REPORTS,
    view: `${ROOTS.REPORTS}/view`,
  },
  //USERS
  users: {
    root: ROOTS.USERS,
    new: `${ROOTS.USERS}/new`,
    view: (id: string | number) => `${ROOTS.USERS}/${id}`,
    edit: (id: string | number) => `${ROOTS.USERS}/${id}/edit`,
  },
  //LOCATION
  sites: {
    root: ROOTS.SITES,
  },
  //SETTINGS
  settings: {
    user: ROOTS.SETTINGS,
    organization: `${ROOTS.SETTINGS}/organization`,
  },
  support: ROOTS.SUPPORT,
  errors: {
    notFound404: '/404',
  },
  //ADMIN
  admin: {
    import: `${ROOTS.ADMIN}/import`,
    actions: `${ROOTS.ADMIN}/actions`,
  },
};
